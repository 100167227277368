<template>
    <div>
        <v-col cols="12" class="ma-0 pt-0 d-flex justify-space-between">
            <span class="ghost">{{ activeTab }}</span>
            <v-row>
                <v-col class="pa-0"> <h1 class="pt-4">Ordenes</h1></v-col>
                <v-col cols="12" md="6" class="_order-1 text-center text-md-right">                             
                    <FormOrder :patient_id="this.$route.params.id" showbutton  @updateOrders="reload_page()"></FormOrder>
                </v-col>
            </v-row>
        </v-col>
        <div v-if="ordersManage.length > 0">
            <!-- <v-row>
                <OrderMiniCard v-for="(order) in order" :key="order.id" :pid="order"></OrderMiniCard>
            </v-row> -->
            <v-row class="mt-2">
                <v-col cols="12" v-for="order in ordersManage" :key="order.id">
                    <router-link class="link" :to="{ name: 'orders', params: { order_id: order.id,patient_id:patient_id}}">
                        <v-card>
                            <v-card-title class="pa-1" :class="colorestado(order.status_name)"></v-card-title>
                            <v-card-text class="pb-1 pt-2">
                                <v-row>
                                    <v-col cols="12" sm="6" md="4" class="item-order">
                                        <p class="pb-1 ma-0"><span class="text-subtitle-2">Tipo: </span><v-chip small color="accent" outlined> <v-icon small>mdi-{{order.order_type==1 ? 'pill' : 'pulse'}} </v-icon>{{order.order_type_name}}</v-chip></p>
                                        <p class="pb-1 ma-0 text-truncate"><span class="text-subtitle-2">Orden: </span><span class="text-lowercase">{{order.clinical_element}}{{order.medicine_name}}</span></p>
                                        <p class="pb-1 ma-0"><span class="text-subtitle-2">Fecha: </span>{{order.created_at | formatTimeZone(order.time_zone_patient)}}</p>
                                        <p class="pb-1 ma-0"><span class="text-subtitle-2">Responsable: </span>{{order.user.name}}</p>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="8">
                                        <v-chip
                                            class="ma-2 order-status"
                                            label
                                            dark
                                            :color="colorestado(order.status_name)"
                                            >
                                            {{order.status_name}}
                                            </v-chip>
                                        <h4>Descripción: </h4>
                                        <span v-if="order.permanent==true">{{getFormattedText(order.to_human, 'durante')}}, por tiempo indefinido.</span>
                                        <span v-else>{{order.to_human}}.</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </router-link>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-card>
                <v-col class="d-flex align-center flex-column message-no-data pa-3">
                    <v-icon size="50">mdi-folder-check</v-icon>
                    <h3>No tiene ordenes por programar</h3>
                </v-col>
            </v-card>
        </div>

        <v-col cols="12" class="pa-0 pt-5">
            <v-card>
                <v-card-text class="pa-2">

                    <v-col class="mt-3 mb-2">
                        <v-row class="d-flex justify-center pt-0">
                            <span class="text-subtitle-1 indigo--text pr-2"><v-icon color="indigo" small>mdi-filter-plus</v-icon> Filtros: </span>   
                            <v-btn v-for="button in Object.keys(filtersType)" :key="button" @click="changeButton(button)" class="ma-1 pt-0 pb-0" :color="filtersType[button]['active'] ? 'indigo lighten-2' : 'indigo lighten-2'" :outlined="!filtersType[button]['active']" dark small height="20px">
                                {{ filtersType[button]['label'] }}
                            </v-btn>                               
                        </v-row>
                        <v-row class="mt-4 d-flex justify-center">
                            <BaseSelectMult v-if="filtersType['type']['active']" v-model="filter.type" type="text" label="Tipo" list_type="tipo_orden" outlined="outlined" filter="filter"></BaseSelectMult>
                            <v-btn v-if="showBtnFilter.length != 0" @click="toFilter" elevation="0" class="ml-5 mt-n1" :disabled="disableFilter" color="indigo lighten-4 indigo--text text--darken-4 " >filtrar
                            </v-btn>
                        </v-row>
                    </v-col>
                    <div>
                        <v-row>
                            <v-col>         
                                <v-data-table :headers="headersClosed" :items="orders" :server-items-length="totalItems" :loading="spinnerClosed" class="elevation-1 GdiTable" item-key="name"
                                :footer-props="{'items-per-page-options': [5, 10, 20], 'items-per-page-text': 'Alertas por página'}"
                                @update:page="updatePage" @update:items-per-page="updateItemsPerPage">
                                    <template v-slot:[`item.created_at`]="{item}">
                                        <p>{{item.created_at|formatDate}}</p> 
                                    </template> 
                                    <template v-slot:[`item.status_name`]="{item}">
                                        <v-chip :color="colorestado(item.status_name)" text-color="white">{{item.status_name}}</v-chip>
                                    </template>                                   
                                    <template v-slot:[`item.order_type_name`]="{item}">
                                        <p>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-chip  v-bind="attrs"
                                                    v-on="on" class="pa-1 mr-1" :color="item.notify==true ? 'success' : 'grey'" label x-small text-color="white"> <v-icon x-small class="pl-0 pr-0"> mdi-{{item.notify==true ? 'bell' : 'bell-off'}} </v-icon></v-chip>
                                                </template>
                                                <span>
                                                    {{item.notify==true ? 'Notificaciones Activas' : 'Notificaciones Pausadas'}}
                                                </span>
                                            </v-tooltip>
                                            <v-icon small>mdi-{{item.order_type==1 ? 'pill' : 'pulse'}} </v-icon>
                                            {{item.order_type==0 ? 'Medición' : 'Medicamento'}} - {{item.clinical_element}}  {{item.medicine_name}}
                                        </p>
                                    </template>
                                    <template v-slot:[`item.actions`]="{item}">
                                        <v-btn @click="goOrder(item.id, item.patient.id)" color="indigo lighten-1" outlined small><v-icon class="mr-1" small>mdi-{{item.status >= 3 ? 'eye' : 'pencil'}}</v-icon> {{item.status >= 3 ? 'Ver' : 'Gestionar'}}</v-btn> 
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </div> 
                </v-card-text>
            </v-card>
        </v-col>
    </div>
</template>
<script>

import AxiosServices from '@/services/AxiosServices';
import FormOrder from '../forms/FormOrder.vue';
import BaseSelectMult from "@/components/forms_items/SelectMultiple.vue";

// import OrderMiniCard from './OrderMiniCard.vue';

export default{
    name:'OrderTab',
    data(){
        return{
            dialog:false,
            ordersManage:[],
            orders:[],
            order:{},
            patient_id:this.$route.params.id,
            spinnerClosed:false,
            headersClosed: [
                    { text: 'Paciente', value: 'patient.name'},
                    { text: 'Fecha', value: 'created_at' },
                    { text: 'Estado', value: 'status_name'},
                    { text: 'Tipo', value: 'order_type_name'},
                    { text: 'Acciones', value: 'actions', active: true, sortable: false},
                ],
            filter:{
                type:undefined
            },
            filtersType:{
                'type':{'active':false, 'label':'Tipo', 'filter':['type']},
            },
            totalItems: 10,
            per_page:10,
            page:1,
        }
    },
    mounted(){
        this.getOrders()
        this.getOrdersManage()
        this.getFormattedText()
    },
    methods:{
        updatePage(page){
            this.page = page
            this.getOrders()
        },
        updateItemsPerPage(per_page){
            this.per_page = per_page
            this.page = 1
            this.getOrders()
        },
        changeButton:function(button){
            if (this.filtersType[button]['active']){
                this.filtersType[button]['filter'].forEach(field => {
                    this.setDefaultFilter(field)
                })
            }
            this.filtersType[button]['active']= !this.filtersType[button]['active']
        },
        setDefaultFilter:function(nameField){    
                this.filter[nameField]=undefined
                this.getOrders()
        },
        selectedFilter(index) {
            this.buttonFilters[index].selected = !this.buttonFilters[index].selected;
        },
        toFilter(){
            this.getOrders()
        },

        getOrdersManage(){
            let params = {filter:'0'}
            AxiosServices.patientOrdersList(this.$route.params.id, params ).then(res => {
                this.ordersManage=this.sortArray(res.data.data.data)
            }).catch(error => {
                console.log('el error trae', error);
            })
        },
        getOrders(){
            let params = this.buildParams()
            this.spinnerClosed = true
            AxiosServices.patientOrdersList(this.$route.params.id, params).then(res => {
                this.orders=this.sortArray(res.data.data.data)
                this.totalItems = res.data.data.total
                this.page = res.data.data.page
            }).catch(error => {
                console.log('el error trae', error);
            }).finally(() =>{
                this.spinnerClosed=false
            })             
        },
        buildParams() {
            let params = {page: this.page, per_page: this.per_page}            
            params.filter = '3';                 
            this.showBtnFilter.forEach(field => {
                if (this.filter[field] !== '' || this.filter[field] == 0) {
                params[field] = this.filter[field]
                }
            });
            return params
        },
        sortArray(array){
            array.sort(function(field1, field2){
                if (field1.status < field2.status) {
                    return -1;
                } else if (field1.status > field2.status) {
                    return 1;
                } else {
                    return 0;
                }
            })
            return array
        },
        getFormattedText(originalText, text) {
        if (typeof originalText === 'string') {
            const parts = originalText.split(text);
            if (parts.length > 1) {
            return parts[0].trim();
            }
        }
        return '';
        },
        reload_page(){
            this.getOrdersManage()
        },
        goOrder(order,patient_id){  
            setTimeout(() => {
                this.$router.push({name: 'orders', params: { order_id: order,patient_id:patient_id}})    
            }, 500); 
        },
        colorestado:function(estado){
            
            switch (estado){
                case "Cancelada":
                return "red"                   
                case "Completada":
                return "success"                    
                case "En ejecución":
                return "yellow darken-3"               
                case "No Gestionada":
                return "grey"                   
                case "Programada":
                return "primary"
                default:
                return "blue-grey darken-2"
                
            }
        },
    },
    computed:{

        activeTab () {
            if (this.$parent.$parent.isActive) {
                // this.getOrders()
            }
            return this.$parent.$parent.isActive
        },
        showBtnFilter() {
            let select = []
            Object.keys(this.filtersType).forEach(element => {
                if (this.filtersType[element]['active']) {
                    select.push(this.filtersType[element]['filter'])
                }
            });
            return select.flat()
        },
        disableFilter() {
            let disable = []
            this.showBtnFilter.forEach(field => {
                if (this.filter[field] == '' || this.filter[field] == null) {
                    if (this.filter[field]!==0){
                        disable.push(field)
                    }
                }
            });
            return disable.length > 0
        }
    },
    components:{
        FormOrder,
        BaseSelectMult
        // OrderMiniCard
    }



}
</script>
<style>
    .item-order p  span{
        color: #546E7A;
    }
    .order-status{
        position: absolute;
top: -3px;
right: 20px;
color: white;
    }
</style>