<template>
    <v-toolbar class="toolbarglya elevation-0 indigo lighten-4 fixed-bar">
        <h3 class="pl-5 client  d-none d-sm-flex d-xl-none"> <v-icon class="pb-2">mdi-hospital-building</v-icon> {{user.client_name}} </h3>
        <v-spacer></v-spacer>
        <div>
            <v-row>
                <div class="d-flex justify-space-around">
                    <v-menu offset-y class="menu-user">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn large rounded depressed color="indigo lighten-4" v-bind="attrs" v-on="on">
                                <v-avatar class="mr-3 white border-avatar">
                                    <v-img :src="user.client_logo_url != '' ? user.client_logo_url : imagen" :alt="user.client_name"></v-img>
                                </v-avatar>
                                <h4 class="text-white">{{ user.first_name }} {{ user.last_name }}</h4>
                            </v-btn>
                        </template>
                        <v-card class="mx-auto text-blue-grey-darken-1" width="210">
                            <v-card-text>
                                <div class="text-center">
                                    <img :src="user.client_logo_url != '' ? user.client_logo_url : imagen" :alt="user.client_name" width="100">
                                </div>
                                <div class="text-left">
                                    <p class="mb-0"><b>Cliente:</b> {{user.client_name}} </p>
                                    <p class="ghost">{{watcher}}</p>
                                    <!-- <p class="mb-0"><b>Sede:</b> {{ user.sede }}</p> -->
                                    <p class="mb-0"><b>Usuario:</b> {{ user.first_name }} {{ user.last_name }}</p>
                                    <!-- <p class="mb-0"><b>Rol: </b> <v-chip class="chip-rol" outlined
                                            :color="user.role == 'Admin' ? 'green' : 'white'" size="small">
                                            {{ user.role }}
                                        </v-chip></p> -->
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </div>
                <v-tooltip bottom left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="logout" color="transparent" elevation="0">
                            <v-icon color="indigo lighten-1" dark v-bind="attrs" v-on="on"> mdi-export</v-icon>
                        </v-btn>
                    </template>
                    <span>Cerrar sesión</span>
                </v-tooltip>
            </v-row>
        </div>
    </v-toolbar>
</template>
<script>

    export default {
        name: 'ToolbarGlya',
        mounted() {
            this.resetTimer();
            document.addEventListener('mousemove', this.resetTimer);
        },

        data: () => ({
            drawer: null,
            imagen: "https://i.postimg.cc/8CTZhPcK/glya.png",
        }),
        methods: {
            logout(){
                console.log('cerraré');
                this.$store.dispatch("logOut",this.$route.name);
                this.$store.commit('SET_LAYOUT', 'ClearLayout')
                if (window.location.pathname !== '/') {
                window.location.replace('/');
                } else {
                window.location.reload();
                }
            },
            resetTimer(){
                if (this.$route.name != 'home') {   
                clearTimeout(this.inactivityTimer);
                const time = 600000;
                    this.inactivityTimer = setTimeout(this.logout,time);
                }
            },

        },
        computed: {
            watcher: function () {
                var see = true
                if (localStorage.getItem('token_glya') != null && localStorage.getItem('token_glya').length > 0) {
                    if (localStorage.getItem('token_glya') != this.$store.getters.token) {
                        this.$store.commit('SET_USER', JSON.parse(localStorage.getItem('user_full')))
                    }
                } else {
                    see = false
                    this.$store.dispatch('logOut', this.$route.name)
                }
                return see
            },
            user:function(){
                return this.$store.state.user
            }
        }

    }
</script>
<style>
    .toolbarglya{
        max-height: 64px;
        margin-top: -6px;
    }
    .border-avatar{
        border: 1px solid white;
    }
    .menu-user{
        box-shadow: none;
    }
    @media (max-width: 420px) {
        .fixed-bar {
        position: sticky;
        position: -webkit-sticky; /* for Safari */
        top: 0em;
        z-index: 2;
        }
    } 
    .client, .client .v-icon{
        color: rgb(110, 119, 177) !important
    }

</style>