<template>
  <v-container >
    <div>
      <v-row>
        <v-col cols="12">
          <v-card >
            <v-card-title  class="yellow darken-3 pt-2 pb-0 white--text">
              <v-icon size="30" class="pb-2 pr-3" dark>mdi-bell</v-icon>Mis Alertas asignadas ({{ alerts_assigned.length }})
            </v-card-title>
            <v-card-text class="pt-3">
              <div v-if="alerts_assigned.length > 0 ">
                <v-row class="pa-3" >
                    <v-col cols="12" md="4" sm="6" class="pa-2" v-for="alert_assigned in alerts_assigned" :key="alert_assigned.id">
                        <CardAlert @reloadAlerts="startComponent()" source="management" :patient_id="alert_assigned.patient.id" :alert_prop="alert_assigned"></CardAlert>                       
                    </v-col> 
                </v-row>
            </div>
            <div v-else>
                <v-col class="d-flex align-center flex-column message-no-data pa-0">
                    <v-icon size="50">mdi-bell</v-icon>
                    <h3>No tiene Alertas por gestionar</h3>
                </v-col>
            </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="autoheight"> 
            <v-card-text>
              <h2>Alertas Abiertas</h2>
              <alerts-total-chart></alerts-total-chart>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card lass="blue darken-3"> 
            <v-card-text>
              <h2>Cumplimiento de Mediciones</h2>
              <v-tabs v-model="tab2" centered>
                <v-tabs-slider></v-tabs-slider>
                <v-tab @click="setLast7days" href="#tab-1">Últimos 7 Días</v-tab>   
                <v-tab @click="setLastMonth" href="#tab-2">Últimos 30 Días</v-tab>
                <v-tab @click="setToday" href="#tab-3">Hoy</v-tab>
                <!-- <v-tab href="#tab-4">Personalizado -->
                <!-- </v-tab> -->
              </v-tabs>
              <v-tabs-items v-model="tab2">
                <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
                  <v-card flat>
                    <compliance-chart :type="i" :start_at="start_at" :end_date="end_at" ></compliance-chart>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>    
              
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
              <v-card-text>
                <h2>Alertas Asignadas</h2>
                <alerts-per-users></alerts-per-users>
              </v-card-text>
            </v-card>
        </v-col>
      </v-row>
  
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pa-4">
                  <h2 class="mb-3">Resumen de mediciones</h2>
                  <v-tabs v-model="tab" centered>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab href="#tab-2">Últimos 7 Días</v-tab>   
                    <v-tab href="#tab-3">Últimos 30 Días</v-tab>
                    <v-tab href="#tab-1">Hoy</v-tab>
                    <v-tab href="#tab-4">Personalizado</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item v-for="i in 4" :key="i" :value="'tab-' + i">
                      <v-card flat>
                        <risk-chart :client="client_id" :type="i"></risk-chart>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>           
                </v-col>
              </v-row>
            </v-card-text>
          </v-card> 
        </v-col>
      </v-row>
    </div>
    <!-- <div v-else>
      <h1>Dashboard</h1>
      <v-col class="d-flex align-center flex-column message-no-data">
        <v-icon size="100">mdi-cogs</v-icon>
        <h1>Página en construcción</h1>
      </v-col>
    </div> -->
  </v-container>
</template>

<script>
import riskChart from "@/components/charts/riskChart.vue";
import complianceChart from "@/components/charts/complianceChart.vue";
import alertsTotalChart from "@/components/charts/alertsTotal.vue";
import alertsPerUsers from "@/components/charts/alertsPerUsers.vue";
import CardAlert from '../views/CardAlert.vue';
import AxiosServices from "@/services/AxiosServices";


export default {
  name: "MainPage",
  data() {
    return {
      client_id: "prueba",
      tab: null,
      tag_id:'none',
      tab2: null,
      alerts_count:0,
      start_at:new Date().toISOString().split('T')[0],
      end_at:new Date().toISOString().split('T')[0],
      showPersonalized:false,
      alerts_assigned:[],
    };
  },
  mounted () {
    this.getAlerts();
    this.setLastMonth();
    this.getOpenAlerts()
  },
  methods: {
    getOpenAlerts:function(){
            AxiosServices.clientAlertsList(this.$store.state.user.client_id,0)
            .then(res => {
                this.filterAndDivideAlerts(res.data.data.data)
            }).catch(error => {
                console.log('el error es',error);
            })
        },
    filterAndDivideAlerts:function(data){
      this.alerts_assigned=data.filter((alert) => alert.user_id == this.$store.state.user.id)
        },
    setToday:function(){
      this.start_at=new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0]
      this.end_at=new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0]
      this.showPersonalized=false
    },
    setLast7days:function(){
      this.start_at=new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('es-ES', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).split('/').reverse().map((value, index) => index === 1 ? value.padStart(2, '0') : value).join('-')
      this.showPersonalized=false
    },
    setLastMonth:function(){
      this.start_at=new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString('es-ES', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).split('/').reverse().map((value, index) => index === 1 ? value.padStart(2, '0') : value).join('-')
      this.showPersonalized=false
    },
    setPersonalized:function(){
    

    },   
    test() {
      var axios = require("axios").default;
      console.log("User_id: ", localStorage.getItem("user_id"));
      console.log("User:", localStorage.getItem("user"));
      var options = {
        method: "GET",
        url: this.$URL + "reports",
        params: { user_id: localStorage.getItem("user_id") },
        headers: {
          authorization: "Bearer " + localStorage.getItem("access_token"),
          user_id: localStorage.getItem("user_id"),
        },
      };

      axios
        .request(options)
        .then(function (response) {
          console.log("Data retrived", response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    sendResponse: function () {
      var response = { success: false, message: "esto es una pinche prueba" }; //cambiando el true por false cambia el color :) 👍 😀

      this.$store.dispatch("addNotification", response);
    },
    getAlerts(){
      AxiosServices.clientAlertsList(this.$store.state.user.client_id,0)
        .then(res => {
          this.alerts_count=res.data.data.length            
        }).catch(error => {
            console.log('el error es',error);
        })
    },
    goAlerts:function(){  
        this.$router.push({name:"alerts_management"})    
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "MainLayout");
  },
  computed: {
    user:function(){
      return this.$store.state.user
    }
  },
  components: {
    riskChart, complianceChart, alertsTotalChart, alertsPerUsers, CardAlert
  },
};
</script>
<style>
  .line-height-1{
    line-height: 1rem;
  }
  .estado{
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    border-radius: 5px 0px 0px 0px;
  }
  .estado-gest{
    background-color: blue;
  }
  .autoheight{
    height: 100%;
  }
</style>
