<template>
    <div>
        <span class="ghost">{{ activeTab }}</span>
        <v-row class="mt-2">
            <v-col cols="12" md="8">
                <v-card class="mb-3">
                    <v-card-title class="indigo lighten-1 white--text pa-0 pl-5 card-title-show">
                        <span class="text-body-1 pt-1 pb-2">
                            Dispositivos asociados
                        </span>
                        <v-spacer></v-spacer>
                        <v-dialog  v-if="(patient.gateway.length == 0 || patient.gateway == null) && client_multipatient=='true'" transition="dialog-bottom-transition" v-model="dialogGateway" max-width="500" persistent>
                            <template v-slot:activator="{ on, attrs }"> 
                                    <v-btn v-if="!permissions.edit" icon color="white" class="edit-carer" :disabled="!permissions.edit">
                                        <v-icon size="30px">{{patient.gateway == '' && patient.card_id == '' ? 'mdi-plus-circle' : 'mdi-pencil-circle'}}</v-icon>
                                    </v-btn>
                                    <v-btn v-else icon color="white" class="edit-carer" v-bind="attrs" v-on="on">
                                        <v-icon size="30px">{{patient.gateway == '' && patient.card_id == '' ? 'mdi-plus-circle' : 'mdi-pencil-circle'}}</v-icon>
                                    </v-btn>                              
                            </template>
                            <template v-slot:default>
                                <v-card>
                                    <v-toolbar color="indigo lighten-1 text-h6" dark height="50">Asociar dispositivo</v-toolbar>
                                    <v-card-text>
                                        <p class="pa-3 pb-0">Si el paciente posee una tarjeta de identificación para un dispositivo Glady multipaciente, puedes registrar el número de tarjeta a continuación:</p>
                                        <div class="text-h2 mt-4">
                                            <v-col cols="12" class="pt-0 pb-0" >
                                                <BaseInput v-model="patient.card_id" type="text" validador="required" label="Número de tajeta"></BaseInput>
                                            </v-col>                                   
                                        </div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions class="justify-end">
                                        <v-btn @click="closeGateway" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                        <v-btn @click="saveGateway" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small :disabled="verror"> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-card-title>
                    <v-card-text class="text--primary pa-4">
                        <div v-if="patient.gateway != null && patient.gateway != ''">
                            <v-card>
                                <v-list-item >
                                    <v-list-item-avatar rounded size="30" color="green lighten-1">
                                        <v-icon class="white--text">mdi-router-wireless</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-row class="pl-3 pr-5">
                                            <div>
                                                <v-list-item-title class="text-overline">Glady</v-list-item-title>                 
                                                <v-list-item-subtitle class="color-text-glya text-body-2">
                                                    <p class="mb-0">{{patient.gateway}} </p>
                                                </v-list-item-subtitle> 
                                            </div>
                                        </v-row>
                                    </v-list-item-content>        
                                </v-list-item>
                            </v-card>
                        </div>
                        <div v-else-if="patient.card_id != null && patient.card_id != ''">
                            <v-card>
                                <v-list-item>
                                    <v-list-item-avatar rounded size="30" color="green lighten-1">
                                        <v-icon class="white--text">mdi-router-wireless</v-icon>
                                        <v-icon class="mult-gateway-info" color="white" size="12px">mdi-account-multiple</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-row class="pl-3 pr-5">
                                            <div>
                                                <v-list-item-title class="text-overline">número de tarjeta</v-list-item-title>                 
                                                <v-list-item-subtitle class="color-text-glya text-body-2">
                                                    <p class="mb-0">{{patient.card_id}} </p>
                                                </v-list-item-subtitle> 
                                            </div>
                                        </v-row>
                                    </v-list-item-content>        
                                </v-list-item>
                            </v-card>
                        </div>
                        <div v-else>
                            <v-col class="d-flex align-center flex-column message-no-data pa-0">
                                <v-icon size="40">mdi-router-wireless</v-icon>
                                <h3>No tiene dispositivos vinculados</h3>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
                
                <v-card class="mx-auto">
                    <v-card-title class="indigo lighten-1 white--text pa-0 pl-5 card-title-show">
                        <span class="text-body-1">
                            Cuidadores
                        </span>
                        <v-spacer></v-spacer>
                        <FormCaregiver modal_name="Agregar Cuidador" @updateCaregiver="getCaregiver" :createsForm="true"></FormCaregiver>
                        <!-- <FormBasicData :createsForm="true" icon="mdi-plus-circle" modal_name="Añadir cuidador"  model_prop="caregivers" :nid="patient.nid"></FormBasicData> -->
                    </v-card-title>
                    <v-card-text class="text-body-1 pa-4">
                        <div v-if="caregivers.length >= 1">
                            <v-card v-for="caregiver in caregivers" :key="caregiver.id" class="mb-2" outlined >
                                <v-list-item >
                                    <v-list-item-avatar rounded size="40" color="indigo lighten-1">
                                        <v-icon class="white--text">mdi-account-circle</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="pa-3">
                                        <v-row class="d-flex justify-space-between">
                                            <div>
                                                <v-list-item-title class="text-overline pt-2"> 
                                                    {{caregiver.first_name}} {{caregiver.last_name}} <br class="br-responsive">                                                    
                                                </v-list-item-title>                 
                                                <v-list-item-subtitle class="color-text-glya text-body-2">
                                                    <p class="mb-0">{{caregiver.id_type_name}} {{caregiver.nid}} <b> / </b> 
                                                        <v-icon small>mdi-cellphone</v-icon>
                                                        <span v-if="caregiver.cellphone == '' || caregiver.cellphone == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                                            Dato Incompleto</v-chip>
                                                        </span>
                                                        <span v-else>{{caregiver.prefix}} {{caregiver.cellphone}}</span>
                                                        <b> / </b> <v-icon small>mdi-email</v-icon>
                                                        <span v-if="caregiver.email == '' || caregiver.email == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                                            Dato Incompleto</v-chip>
                                                        </span>
                                                        <span v-else>{{caregiver.email}}</span>
                                                    </p>
                                                    <p>
                                                        <v-chip :color="caregiver.notification ? 'success' : 'grey'" small outlined>
                                                            <v-icon class="mr-1" x-small >mdi-{{caregiver.notification ? 'bell-ring' : 'bell-off'}}</v-icon>
                                                            {{caregiver.notification ? 'Notificaciones Activas' : 'Notificaciones Apagadas'}}</v-chip>
                                                        <!-- <span>{{ caregiver.notification }}</span> -->
                                                    </p>                        
                                                </v-list-item-subtitle>
                                            </div>
                                            <div>
                                                <FormCaregiver :edit="true" modal_name="Editar Cuidador" @updateCaregiver="getCaregiver" :createsForm="false" :caregiver_in="caregiver"></FormCaregiver>
                                            </div>
                                        </v-row>
                                    </v-list-item-content>        
                                </v-list-item>
                            </v-card>
                        </div>
                        <div v-else>
                            <v-col class="d-flex align-center flex-column message-no-data pa-0">
                                <v-icon size="40">mdi-home-heart</v-icon>
                                <h4>No se han agregado cuidadores</h4>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="4">
                <v-card>
                    <v-card-title class="indigo lighten-1 white--text pa-0 pl-5 card-title-show">
                        <span class="text-body-1">
                            Datos demográficos
                        </span>
                        <v-spacer></v-spacer>
                        <v-dialog transition="dialog-bottom-transition" v-model="dialog" max-width="800" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-if="!permissions.edit" :disabled="!permissions.edit" icon color="white" class="edit-carer" >
                                    <v-icon size="30px">mdi-pencil-circle</v-icon>
                                </v-btn>
                                <v-btn v-else icon color="white" class="edit-carer" v-bind="attrs" v-on="on">
                                    <v-icon size="30px">mdi-pencil-circle</v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default>
                                <v-card>
                                    <v-toolbar color="indigo lighten-1 text-h6" dark height="50">Información complementaria</v-toolbar>
                                    <v-card-text>
                                        <div class="text-h2 mt-4">
                                            <v-row justify="center">
                                                <v-col cols="12 mt-3" class="carer-modal">
                                                    <div>
                                                        <v-row>                                
                                                            <v-col cols="12" md="4" class="pt-0 pb-0">
                                                                <BaseSelect v-model="patient.occupation" type="text" list="occupations" label="Ocupación" :valuex="patient.occupation"></BaseSelect>
                                                            </v-col>
                                                            <v-col cols="12" md="4" class="pt-0 pb-0">
                                                                <BaseSelect v-model="patient.scholarship" type="text" list="scholarships" label="Escolaridad" :valuex="patient.scholarship"></BaseSelect>
                                                            </v-col>
                                                            <v-col cols="12" md="4" class="pt-0 pb-0">
                                                                <BaseSelect v-model="patient.civil_status" type="text" list="civil_statuses" label="Estado Civil" :valuex="patient.civil_status"></BaseSelect>
                                                            </v-col>
                                                            <!-- <v-col cols="12" md="4" class="pt-0 pb-0">
                                                                <BaseSelect v-model="patient.religion" type="text" list="religions" label="Religión"></BaseSelect>
                                                            </v-col> -->
                                                            <!-- <v-col cols="12" md="4" class="pt-0 pb-0">
                                                                <BaseSelect v-model="patient.ethnic_group" type="text" list="ethnic_groups" label="Grupo Étnico"></BaseSelect>
                                                            </v-col>                                                               -->
                                                            <!-- <v-col cols="12" md="4" class="pt-0 pb-0">
                                                                <BaseCheckbox v-model="patient.displaced" color="indigo" positive="Si" negative="No" margin="negative-margin-30" label="¿Es desplazado?"></BaseCheckbox>
                                                            </v-col> -->
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions class="justify-end">
                                        <v-btn @click="closeDemographic" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                        <v-btn @click="saveDemographic" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small :disabled="verror"> Guardar <v-icon right dark > mdi-check</v-icon></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-card-title>
                    <v-card-text class="text--primary pa-4">
                        <p class="mb-0">
                            <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b>Ocupación: </b>
                                <span v-if="patient.occupation_name == '' || patient.occupation_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>
                                <span v-else>{{patient.occupation_name}}</span>
                            </span>
                        </p>
                        <p class="mb-0">
                            <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Escolaridad: </b>
                                <span v-if="patient.scholarship_name == '' || patient.scholarship_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>
                                <span v-else>{{patient.scholarship_name}}</span>
                            </span>
                        </p>
                        <p class="mb-0">
                            <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Estado Civil:</b>
                                <span v-if="patient.civil_status_name == '' || patient.civil_status_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>
                                <span v-else>{{patient.civil_status_name}}</span>
                            </span>
                        </p>
                        <!-- <p class="mb-0">
                            <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Religión:</b>
                                <span v-if="patient.religion_name == '' || patient.religion_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>
                                <span v-else>{{patient.religion_name}}</span>
                            </span>
                        </p> -->
                        <!-- <p class="mb-0">
                            <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">Grupo Etnico:</b>
                                <span v-if="patient.ethnic_group_name == '' || patient.ethnic_group_name == null"><v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>
                                    Dato Incompleto</v-chip>
                                </span>
                                <span v-else>{{patient.ethnic_group_name}}</span>
                            </span>
                        </p> -->
                        <!-- <p class="mb-0">
                            <v-icon small class="text--lighten-2 indigo--text">mdi-circle-medium</v-icon><span> <b class="mr-1">¿Es desplazado?</b>
                                <span>{{patient.displaced==true ? 'SI' : 'NO'}}</span>
                            </span>
                        </p> -->
                    </v-card-text>
                </v-card>
                <v-card class="mt-3">
                    <v-card-text class="text--primary pa-4">
                        <v-dialog transition="dialog-bottom-transition" v-model="dialogDeceased" max-width="500" persistent>
                            <template v-slot:activator="{ on, attrs }"> 
                                <v-btn v-if="!permissions.set_deceased" class="white--text" color="blue-grey darken-1" block small :disabled="!permissions.set_deceased">Paciente Fallecido</v-btn>                            
                                <v-btn v-else class="white--text" color="blue-grey darken-1" block small v-bind="attrs" v-on="on">Paciente Fallecido</v-btn>                            
                            </template>
                            <template v-slot:default>
                                <v-card>
                                    <v-card-text class="pb-0">
                                        <v-col class="pa-5 mt-3 text-center">
                                            <p>Recuerde que al cambiar el estado de un paciente a <b>fallecido</b> se cancelarán todas las órdenes y actividades relacionadas y no podrá realizar ninguna acción sobre el mismo, esta acción no es reversible.</p>
                                            <p>¿Desea actualizar a <b>{{patient.first_name }}  {{patient.last_name}}</b> como paciente Fallecido?</p>
                                        </v-col>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions class="justify-end">
                                        <v-btn @click="dialogDeceased=false" class="pl-5 pr-5 white--text" depressed color="red accent-2" small >Cerrar <v-icon right dark > mdi-close</v-icon> </v-btn>
                                        <v-btn @click="saveDeceased" class="pl-5 pr-5 white--text" depressed color="green lighten-1" small :disabled="verror"> Actualizar <v-icon right dark > mdi-check</v-icon></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>

</template>
<script>

import FormCaregiver from '@/components/forms/FormCaregiver.vue'
import BaseSelect from '@/components/forms_items/BaseSelect.vue'
// import BaseCheckbox from '@/components/forms_items/BaseCheckbox.vue'
// import SelectSearch from '@/components/forms_items/SelectSearch.vue';
import BaseInput from '@/components/forms_items/BaseInput.vue';
import AxiosServices from '@/services/AxiosServices';




export default {

    mounted(){
        this.getPatient()        
        this.getCaregiver()
    },    
    data(){
        return{  
            model_prop: {
                type:String,
                default: "patients"
            },
            dialogGateway:false,
            dialogDeceased:false,
            dialog:false,
            city:false,
            on:false,
            patient:{gateway:'',card_id:null,occupation:'',scholarship:'',civil_status:''},
            ciudad:false,
            caregivers:[],
            permissions:{}
        }},
        props:{
            model: {
                type: String,
                default: 'Patient'
            },
            id: {
                type: String,
                default:''
            }
        },
        methods:{
            saveGateway:function(){
                let pat={}
                pat['card_id']=this.patient.card_id
                this.saveAction(pat)                
            },
            closeGateway:function(){
                this.dialogGateway = false
                this.getPatient()              
            },
            // deleteGateway: function () {
            //     this.patient.card_id = null
            //     this.saveGateway()
            // },
            saveDemographic:function(){
                let pat = {}
                pat['occupation']=this.patient.occupation
                pat['scholarship']=this.patient.scholarship
                pat['civil_status']=this.patient.civil_status
                pat['religion']=this.patient.religion
                pat['ethnic_group']=this.patient.ethnic_group
                pat['displaced']=this.patient.displaced
                this.saveAction(pat)
            },
            closeDemographic(){
                // this.patient = {occupation:'',scholarship:'',civil_status:''},
                // 
                // this.patient.occupation = ''
                // this.patient.scholarship = ''
                // this.patient.civil_status = ''
                this.getPatient()
                this.dialog=false
            },
            saveAction: function(patient){
                AxiosServices.patientUpdate(this.patient.id, patient).then(res => {
                    this.dialogGateway = false
                    this.dialog = false
                    this.$store.dispatch("addNotification", res.data)
                    this.getPatient()
                })                  
            },            
            evalFields: function () {
                for (const field in this.$store.state.fields_select) {
                    if (typeof this.patient[field] == "object") {
                        this.patient[field] = this.patient[field].id != null ? this.patient[field].id : this.$store.state.fields_select[field].id
                    }
                }
            },
            getPatient: function () {
                console.log('empecé a traer el paciente');
                AxiosServices.patientDetail(this.$route.params.id).then(res => {
                    this.patient = res.data.data
                    this.permissions = res.data.data.permissions
                }).catch((error) => {
                    if (error.code !='ECONNABORTED') {
                        this.$store.dispatch("addNotification", error.response.data);
                        this.$store.dispatch("render404");          
                    }
                })
            },
            getCaregiver: function () {
                AxiosServices.patientCaregiversList(this.$route.params.id).then(res=>{
                        this.caregivers = res.data.data                   
                    })
            },
            saveDeceased() {
                this.patient.status = 3
                AxiosServices.patientUpdate(this.patient.id, this.patient).then(res => {
                    this.dialogDeceased = false
                    this.$store.dispatch("addNotification", res.data)
                    this.getPatient()
                    window.location.reload();
                }).catch((error) => {
                    if (error.code !='ECONNABORTED') {
                        this.$store.dispatch("addNotification", error.response.data);
                    }        
                });
                
            },
        },
        computed: {
            activeTab: function () {
                if (this.$parent.$parent.isActive) {
                    this.getPatient()
                }
                return this.$parent.$parent.isActive
            },           
            verror:function(){
                return this.$store.state.form.reserror
            },
            client_multipatient:function(){
                return  localStorage.getItem('client_with_multipatient_glady')
            },
        },
        components:{
        BaseSelect,
        // BaseCheckbox,
        // SelectSearch,
        FormCaregiver,
        BaseInput

    }
    
}

</script>
<style>
    .mult-gateway-info{
        position: absolute;
        background-color: rgb(102, 187, 106);
        height: 12px !important;
        border-radius: 10px;
        left: -7px;
        bottom: -8px;
    }
</style>