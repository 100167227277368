<template>
  <div>
    <TitleView title="Pacientes"></TitleView>
    <div v-if="query != undefined && patientlist.length == 0">
      <v-col class="d-flex align-center flex-column message-no-data">
        <v-icon size="100">mdi-account-search</v-icon>
        <h1>No se encontraron resultados</h1>
        <router-link to="/patients" class="mt-5">
          <v-btn color="indigo" class="white--text" small> Búsqueda avanzada <v-icon class="white--text ml-3" small>mdi-arrow-right-thick</v-icon></v-btn>
        </router-link>
      </v-col>
    </div>
    <div v-else>
      <div v-if="query">

        <PatientMiniCard v-for="pat_info in patientlist" :key="pat_info.id"  :patient_info="pat_info"></PatientMiniCard>
      </div>
      <div v-else>
        <v-card>
          <v-card-text>
            <v-col>
              <v-row class="d-flex justify-center pt-0">
                <span class="text-subtitle-1 indigo--text pr-2"><v-icon color="indigo" small>mdi-filter-plus</v-icon> Filtros: </span>   
                <v-btn v-for="button in Object.keys(filtersType)" :key="button" @click="changeButton(button)" class="ma-1 pt-0 pb-0" :color="filtersType[button]['active'] ? 'indigo lighten-2' : 'indigo lighten-2'" :outlined="!filtersType[button]['active']" dark small height="20px">
                  {{ filtersType[button]['label'] }}
                </v-btn>
              </v-row>
            </v-col>
            <v-row class="mt-4 d-flex justify-center">
              <v-col v-if="filtersType['name']['active']" cols="12" sm="6" md="3" class="pt-0 pb-0" >
                <BaseInput v-model="filter.name" label="Nombre" outlined="outlined" filter="filter" validador="none"></BaseInput>
              </v-col>
              <v-col v-if="filtersType['nid']['active']" cols="12" sm="6" md="3" class="pt-0 pb-0" >
                <BaseInput v-model="filter.nid" label="Documento" model="users" outlined="outlined" filter="filter" validador="none"></BaseInput>
              </v-col>
              <v-col v-if="filtersType['tags']['active']" cols="12" sm="6" md="3" class="pt-0 pb-0" >
                <SelectSearch                                    
                  label="Etiquetas" 
                  model="tags" 
                  v-model="filter.tags" 
                  :multiple="true"
                  outlined="outlined" filter="filter"
                  :valuex="filter.tags == '' ? 1 : { id: filter.tags, text: '' }">
                </SelectSearch>
              </v-col>             
              <v-col v-if="filtersType['eapbs']['active']" cols="12" sm="6" md="3" class="pt-0 pb-0" >
                <SelectSearch                                    
                  label="EAPBS" 
                  model="eapbs" 
                  v-model="filter.eapbs" 
                  :multiple="true"
                  outlined="outlined" filter="filter"
                  :valuex="filter.eapbs == '' ? 1 : { id: filter.eapbs, text: '' }">
                </SelectSearch>
              </v-col>
            </v-row>
            <v-row v-if="showBtnFilter.length != 0" justify="center" class="pb-5 pt-0">
              <v-col cols="12" sm="6" class="pb-5 pt-0">
                <v-btn @click="filterPatients" :disabled="disableFilter" block color="indigo lighten-4 indigo--text text--darken-4 " >filtrar
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-col cols="12" md="4" class="pa-0">
              <v-text-field class="mb-2 pt-2 pb-1 filter" placeholder="Buscar" v-model="search" append-icon="mdi-magnify" single-line hide-details outlined></v-text-field>
            </v-col> -->
            <v-data-table :headers="headers" :items="patientlist" :server-items-length="totalItems" :loading="spinnerClosed" :search="search" class="elevation-1 GdiTable" item-key="item.id"
            :footer-props="{'items-per-page-options': [5, 10, 20],'items-per-page-text': 'Alertas por página'}" @update:page="updatePage" @update:items-per-page="updateItemsPerPage">
              <template v-slot:[`item.status`]="{ item }">
                <v-chip class="pa-0 pl-3 pr-3" :color="colorestado(item.status)" small outlined>{{item.status_name}}</v-chip>
              </template>
              <template v-slot:[`item.gateway`]="{ item }" >
                <div style="width:40px">
                  <v-icon :color="item.gateway.length >= 1 || item.card_id.length >= 1 ? 'success' : 'grey lighten-2'">mdi-{{item.gateway.length >= 1 || item.card_id.length >= 1 ? 'router-wireless' : 'router-wireless-off'}}</v-icon>
                  <v-icon v-show="item.card_id!=''" class="mult-gateway" color="green darken-4" size="12px">mdi-account-multiple</v-icon>
                </div>
              </template>
              <template v-slot:[`item.first_name`]="{ item }">
                <span class="capitalize color-text-glya"><b>{{item.first_name}} {{item.last_name}}</b></span>
              </template>
              <template v-slot:[`item.nid`]="{ item }">
                <span>{{item.id_type_name}} {{item.nid}}</span>
              </template>
              <template v-slot:[`item.age`]="{ item }">
                <span>{{item.age}}  Años</span>
              </template>
              <template v-slot:[`item.cellphone`]="{ item }">
                <span v-if="item.cellphone == '' || item.cellphone == null">
                  <v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>Incompleto</v-chip>
                </span>
                <span v-else>{{ item.cellphone }}</span>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <span v-if="item.email == '' || item.email == null">
                  <v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>Incompleto</v-chip>
                </span>
                <span v-else>{{ item.email }}</span>
              </template>
              <!-- <template v-slot:[`item.address`]="{ item }">
                <span v-if="item.address == '' || item.address == null">
                  <v-chip class="pa-0 pl-3 pr-3" color="error" small outlined>Incompleto</v-chip>
                </span>
                <span v-else>{{ item.address}}</span>
              </template> -->
              <template v-slot:[`item.show`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <router-link :to="`patients/${item.id}`">
                      <v-btn  v-bind="attrs" v-on="on" color="indigo lighten-1" outlined x-small> <v-icon small>mdi-eye</v-icon>{{item.none}}</v-btn>
                    </router-link>
                  </template>
                  <span>Ver Paciente</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
    
  </div>
</template>

<script>
import TitleView from '@/components/views/TitleView.vue'
import AxiosServices from '@/services/AxiosServices';
import BaseInput from '@/components/forms_items/BaseInput';
import PatientMiniCard from '@/components/views/PatientMiniCard.vue'
import SelectSearch from '../forms_items/SelectSearch.vue';

export default {
  name: 'PatientsIndex',
  mounted(){
    this.getpatients()
  },
  data(){
    return {
      query:this.$route.query.q,
      patientlist:[],
      spinner:false,
      spinnerClosed:false,
      search: '',
      totalItems: 10,
      per_page:10,
      page:1,
      filter:{
        name:'',
        nid:'',
        tags:'',
        eapbs:''
      },
      headers: [
                { text: "Estado", value: "status" },
                { text: "Glady", value: "gateway" },
                { text: "Nombre", value: "first_name" },
                { text: "N° Documento", value: "nid" },
                { text: "Edad", value: "age" },
                { text: "Teléfono", value: "cellphone"},
                { text: "E-mail", value: "email" },
                // { text: "Dirección", value: "address" },
                // { text: "EAPB", value: "eapb" },
                { text: "", value: "show" }

            ],
            filtersType:{
                'name':{'active':false, 'label':'Nombre', 'filter':['name']},
                'nid':{'active':false, 'label':'N° Documento', 'filter':['nid']},
                'tags':{'active':false, 'label':'Etiquetas', 'filter':['tags']},
                'eapbs':{'active':false, 'label':'EAPB', 'filter':['eapbs']},
              },
    }
  },
  methods:{
    updatePage(page){
            this.page = page
            this.getpatients()
        },
        updateItemsPerPage(per_page){
            this.per_page = per_page
            this.page = 1
            this.getpatients()
        },

    getpatients:function(){      
      this.spinnerClosed=true
      if (this.query == '' || this.query == undefined) {
        this.filterPatients()
      } else {
        AxiosServices.patientSearch(this.query).then(res => {
          this.patientlist = res.data.data.data;
        }).catch(error => {
          console.log('el error es ', error);
          // this.$store.dispatch("addNotification", error.response.data)
        })
      }
      this.spinnerClosed=false 
    },
    filterPatients:function(){
      let params = this.buildParams()
      this.spinnerClosed=true
      AxiosServices.patientListFilters(params)
      .then(res => {
        console.log('está llegando esto', res.data);
        this.patientlist = res.data.data.data
        this.totalItems = res.data.data.total
        this.page = res.data.data.page
      }).catch(error => {
        console.log('el error filtrando es ', error);
      })
      .finally(() => {
        this.spinnerClosed=false
      })
    },
    buildParams:function () {
        let params = {'page': this.page, 'per_page': this.per_page}
        this.showBtnFilter.forEach(field => {
            if (this.filter[field] != '' || this.filter[field]==0){
              params[field] = this.filter[field].toString()               
            }
        });
        return params
    },
    changeButton:function(button){
      if (this.filtersType[button]['active']){
          this.filtersType[button]['filter'].forEach(field => {
              this.setDefaultFilter(field)
          })
      }
      this.filtersType[button]['active']= !this.filtersType[button]['active']
    },
    setDefaultFilter:function(nameField){
        if (nameField=='start_at' || nameField=='end_at') {
            this.filter[nameField]=(new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0])
        } else {
            this.filter[nameField]=''
        }
    },
    colorestado: function (estado) {
      switch (estado) {
      case 0:
        return "grey";
      case 1:
        return "success";
      case 2:
        return "red";
      case 3:
        return "black";
      }
    },
  },
  computed:{
    showBtnFilter() {
      let select = []
      Object.keys(this.filtersType).forEach(element => {
          if (this.filtersType[element]['active']) {
              select.push(this.filtersType[element]['filter'])
          }
      });
      return select.flat()
    },
    disableFilter() {
      let disable = []
      this.showBtnFilter.forEach(field => {
          if (this.filter[field] == '' || this.filter[field] == null) {
              if (this.filter[field]!==0){
                  disable.push(field)
              }
          }
      });
      return disable.length > 0
    }
  },

  watch:{
    showBtnFilter(newValue, oldValue) {
        if (newValue.length == 0 && oldValue.length > 0) {
            this.getpatients()
        }
    },
    //ESTE WATCH ES NECESARIO SOLO POR QUE EL CAMPO DE BUSQUEDA ESTA POR FUERA (SIDEBAR) SI VIENERA
      //DE ADENTRO NO HUBIESE NECESIDAD
    $route() {
      this.query=this.$route.query.q
      this.getpatients()
    }
  },
  components: {
    TitleView,
    BaseInput,
    PatientMiniCard,
    SelectSearch
  }
}
</script>
<style>
  .message-no-data .v-icon, .message-no-data h1, .message-no-data h2, .message-no-data h3, .message-no-data h4, .message-no-data h5 {
    color: rgb(195, 195, 195);
  }
</style>